import React, { useEffect, useRef } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import solucion from "../../../static/img/irstrat/solucion.png"
import ganar from "../../../static/img/irstrat/ganar.png"

const Contenido = (props) => {
  const isMountedComponent = useRef(true)
  useEffect(() => {
    if (isMountedComponent.current) {
      window.jquery_scripts()
    }
    return () => {
      isMountedComponent.current = false
    }
  })
  return (
    <Layout location={props.location}>
      <SEO title="Propuesta de valor"/>
      <Banner title="Propuesta de valor"/>
      <section className="welcome_section_area propuesta">
        <div className="container">
          <div className="section_title black_c">
            <h2>Tesis de servicio</h2>
            <span className="sec_border"></span>
            <p className="f_16">En IRStrat entendemos las relaciones con inversionistas (RI) como una compleja y
              estratégica función, en donde confluyen:</p>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="flaticon-graph"></i></div>
                <div className="media-body">
                  <h3>Trading</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="flaticon-money-1"></i></div>
                <div className="media-body">
                  <h3>Finanzas corporativas</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="fa fa-handshake-o" aria-hidden="true"></i></div>
                <div className="media-body">
                  <h3>Comunicación</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="flaticon-research"></i></div>
                <div className="media-body">
                  <h3>PR estratégico</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="flaticon-money-2"></i></div>
                <div className="media-body">
                  <h3>Derecho financiero</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="media strategy_item align-items-center">
                <div className="round_icon"><i className="fa fa-bullhorn" aria-hidden="true"></i></div>
                <div className="media-body">
                  <h3>Marketing</h3>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="offer_area_two propuesta">
        <div className="container">
          <div className="row strategy_info align-items-center">
            <div className="col-lg-7">
              <div className="ex_content">
                <h2>Solución efectiva</h2>
                <span className="sec_border"></span>

                <p> Hemos conformado un equipo
                  multi-disciplinario y una red de alianzas estratégicas
                  que aglomeran el conocimiento y especialización necesaria para cubrir todas las aristas de RI, en pro
                  de la obtención de soluciones efectivas, teniendo una sólida ventaja sobre otras opciones que sólo
                  cubren realmente actividades de PR o algunos procesos de backoffice.</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div>
                <img className="video_img wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="2.0s"
                     src={solucion} alt={" "} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="b_consultation_area section_gap propuesta">
        <div className="container">
          <div className="b_consultation_content">
            <h2>Adentro hacia afuera</h2>
            <p>Apuntalamos nuestra ventaja en nuestra especialización y amplia
              red de contactos en
              el mercado mexicano; consideramos estratégica la vinculación y exposición a los mercados internacionales,
              que nutrimos constantemente, pero no concebimos que se pueda atender a México sin estar en México.</p>
          </div>
        </div>
      </section>
      <section className="offer_area_two propuesta bg-white">
        <div className="container">
          <div className="row strategy_info align-items-center">
            <div className="col-lg-5">
              <div >
                <img className="video_img wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="2.0s"
                     src={ganar} alt={" "} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="ex_content">
                <h2>Ganar-ganar</h2>
                <span className="sec_border"></span>

                <p>Cuidamos y maximizamos su inversión, para
                  ello, contamos con nuestras propias herramientas de análisis, monitoreo y medición, mediante las
                  cuales buscamos proveer soluciones avanzadas que respondan a sus necesidades específicas, sin
                  erosionar su inversión y tiempo en múltiples equipos y presupuestos.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contenido
